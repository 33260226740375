.data-list-page {
  max-width: 100%;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.page-actions {
  display: flex;
  gap: 0.75rem;
}

.data-list-tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.search-form {
  flex: 1;
  min-width: 250px;
}

.search-field {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.search-field input {
  flex: 1;
  padding: 0.75rem;
  border: none;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.search-field input:focus {
  outline: none;
}

.search-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-actions {
  display: flex;
  gap: 0.75rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
}

.btn-icon {
  margin-right: 0.5rem;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: rgba(var(--primary-color-rgb), 0.9);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn-secondary:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.9);
}

.btn-filter {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.btn-filter:hover, .btn-filter.active {
  background-color: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-refresh {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.btn-refresh:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.1);
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.filter-panel {
  background-color: rgba(var(--secondary-color-rgb), 0.05);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  animation: fadeIn 0.3s;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group label {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.filter-group input,
.filter-group select {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.filter-group input:focus,
.filter-group select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.25);
}

.data-list-table {
  margin-top: 1.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .data-list-tools {
    flex-direction: column;
    align-items: stretch;
  }
  
  .filter-actions {
    justify-content: space-between;
  }
}
/* Styl dla klikalnego wiersza */
.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.clickable-row:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Styl dla nieaktywnych danych */
.text-secondary {
  color: #888;
  font-style: italic;
}
/* Style dla komponentów paginacji */
.MuiPagination-root {
  margin-top: 20px;
  margin-bottom: 10px;
}

.MuiPagination-ul {
  justify-content: center;
}

/* Aby przyciski paginacji były bardziej widoczne */
.MuiPaginationItem-root {
  font-weight: 500;
}

/* Style dla informacji o paginacji */
.pagination-info {
  text-align: center;
  color: #666;
  margin-bottom: 10px;
  font-size: 0.9rem;
}