.settings-page {
  max-width: 100%;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1.5rem;
}

.card-title-with-icon {
  display: flex;
  align-items: center;
}

.card-title-icon {
  margin-right: 0.75rem;
  color: var(--primary-color);
}

.settings-form {
  padding: 1rem 0;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input, 
.form-group select,
.form-select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.form-group input:focus, 
.form-group select:focus,
.form-select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.25);
}

.password-hint,
.checkbox-hint {
  font-size: 0.8rem;
  color: var(--secondary-color);
  margin-top: 0.5rem;
}

.checkbox-group {
  margin-bottom: 1.5rem;
}

.checkbox-group label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.checkbox-group label span {
  margin-left: 0.5rem;
  font-weight: 500;
}

.checkbox-group input[type="checkbox"] {
  width: auto;
  margin-right: 0.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  margin-right: 0.5rem;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: rgba(var(--primary-color-rgb), 0.9);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn-secondary:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.9);
}

@media (max-width: 768px) {
  .settings-grid {
    grid-template-columns: 1fr;
  }
}
