.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 100px;
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn-icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.btn.icon-only {
  padding: 8px;
  min-width: auto;
}

.btn.icon-only .btn-icon {
  margin-right: 0;
}

.btn-sm {
  padding: 4px 8px;
  font-size: 0.875rem;
  min-width: 80px;
}

.btn-md {
  padding: 8px 16px;
  font-size: 1rem;
}

.btn-lg {
  padding: 12px 24px;
  font-size: 1.125rem;
  min-width: 120px;
}

.btn-primary {
  background-color: #0d6efd;
  color: white;
}

.btn-primary:hover:not(:disabled) {
  background-color: #0b5ed7;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover:not(:disabled) {
  background-color: #5c636a;
}

.btn-success {
  background-color: #198754;
  color: white;
}

.btn-success:hover:not(:disabled) {
  background-color: #157347;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover:not(:disabled) {
  background-color: #bb2d3b;
}

.btn-warning {
  background-color: #ffc107;
  color: #212529;
}

.btn-warning:hover:not(:disabled) {
  background-color: #ffca2c;
}

.btn-info {
  background-color: #0dcaf0;
  color: #212529;
}

.btn-info:hover:not(:disabled) {
  background-color: #31d2f2;
}