.profile-page {
  max-width: 100%;
}

.profile-layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1.5rem;
}

.profile-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.avatar-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.profile-name {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  text-align: center;
}

.profile-email {
  margin: 0;
  color: var(--secondary-color);
  text-align: center;
  word-break: break-all;
}

.profile-menu {
  padding: 1rem 0;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  color: var(--text-color);
  transition: all 0.2s;
}

.profile-menu-item svg {
  margin-right: 0.75rem;
  font-size: 1.25rem;
}

.profile-menu-item:hover {
  background-color: rgba(var(--primary-color-rgb), 0.05);
}

.profile-menu-item.active {
  color: var(--primary-color);
  background-color: rgba(var(--primary-color-rgb), 0.1);
  font-weight: 500;
}

.profile-info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-left:2rem;
}

.info-group h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.info-item {
  display: flex;
  margin-bottom: 1rem;
}

.info-icon {
  color: var(--secondary-color);
  font-size: 1.25rem;
  margin-right: 1rem;
  min-width: 1.25rem;
}

.info-label {
  display: block;
  color: var(--secondary-color);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.info-value {
  display: block;
  font-weight: 500;
}

.profile-form {
  padding: 1rem 0;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input, .form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.form-group input:focus, .form-group select:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.25);
}

.form-group input:disabled {
  background-color: rgba(var(--secondary-color-rgb), 0.1);
  cursor: not-allowed;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: rgba(var(--primary-color-rgb), 0.9);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn-secondary:hover {
  background-color: rgba(var(--secondary-color-rgb), 0.9);
}

@media (max-width: 768px) {
  .profile-layout {
    grid-template-columns: 1fr;
  }
  
  .form-row {
    grid-template-columns: 1fr;
  }
}
