.clients-list {
  padding: 20px;
}

.page-title {
  margin-bottom: 20px;
  color: #333;
}

.card-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 300px; /* lub dowolna wartość */
}

.search-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* ikonka nie blokuje kliknięć */
  color: #666;
}

.search-input {
  width: 100%;
  padding-right: 2.5rem; /* zapas na ikonę */
  box-sizing: border-box;
  padding: 8px 10px 8px 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.action-buttons {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #666;
}

.error-message {
  color: #d32f2f;
  padding: 15px;
  text-align: center;
  background-color: #ffebee;
  border-radius: 4px;
}

.empty-message {
  padding: 20px;
  text-align: center;
  color: #666;
}

/* Style dla menu rozwijanego */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 180px;
  padding: 5px 0;
  margin: 2px 0 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

.dropdown-menu a {
  display: block;
  padding: 8px 15px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-menu a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.clients-list .card-body {
  padding: 0;
}
.table-container {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .clients-list {
    padding: 0px;
  }
  .page-title {
    display: none;
  }
  .card-header {
    align-items: center;
  }
  .data-table th, .data-table td {
    padding-left: 1rem; /* zamiast padding: 1rem */
  }
}