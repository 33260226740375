.client-details {
  padding: 20px;
}

.client-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.client-header-info {
  flex: 1;
}

.client-name {
  font-size: 2rem;
  margin: 0;
  color: #333;
}

.client-org {
  margin-top: 0.5rem;
}

.org-name {
  font-size: 1.25rem;
  margin: 0;
  color: #555;
}

.org-address,
.client-address {
  font-size: 1rem;
  color: #777;
}

.client-header-actions button {
  margin-left: 0.5rem;
}

.client-main-info h1 {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #333;
}

.client-organization h2 {
  margin: 0 0 5px 0;
  font-size: 18px;
  color: #555;
}

.client-address {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.client-actions {
  display: flex;
  gap: 10px;
}

.client-basic-info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 1rem 0;
}

.info-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-bottom: 1rem;
}

.info-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #e1e4e8;
  padding-bottom: 8px;
}

.info-section > h3 {
  margin-bottom: 0.5rem;
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.25rem;
}

.info-row {
  display: flex;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  margin-bottom: 0.5rem;
}

.info-label {
  min-width: 120px;
  font-weight: 600;
  color: #555;
}

.info-row .label {
  flex: 0 0 150px;
  font-weight: bold;
  color: #555;
}

.info-value {
  color: #333;
  flex-grow: 1;
}

.info-row .value {
  flex: 1;
  color: #333;
}

.organization-item {
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.empty-tab {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  color: #6c757d;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 50px;
  color: #666;
}

.error-message {
  color: #d32f2f;
  padding: 20px;
  text-align: center;
  background-color: #ffebee;
  border-radius: 4px;
  margin: 20px 0;
}

/* Style dla drukowania */
@media print {
  .client-actions,
  .tabs-header,
  button {
    display: none !important;
  }
  
  .tabs-content {
    display: block !important;
  }
  
  .client-basic-info {
    grid-template-columns: 1fr !important;
  }

  .info-section {
    box-shadow: none;
    page-break-inside: avoid;
  }
  
  .card-actions {
    display: none !important;
  }
}