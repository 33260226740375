.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-color);
  text-align: center;
  padding: 2rem;
}

.not-found-content {
  max-width: 500px;
}

.not-found-icon {
  font-size: 5rem;
  color: var(--warning-color);
  margin-bottom: 1rem;
}

.not-found-page h1 {
  font-size: 5rem;
  margin: 0;
  color: var(--primary-color);
}

.not-found-page h2 {
  font-size: 2rem;
  margin: 0.5rem 0 1.5rem;
}

.not-found-page p {
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.btn-icon {
  margin-right: 0.5rem;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: rgba(var(--primary-color-rgb), 0.9);
}
