.card {
  background-color: var(--card-bg);
  border-radius: 8px;
  box-shadow: var(--shadow);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.card-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
}

.card-actions {
  display: flex;
  gap: 0.5rem;
}

.card-body {
  padding: 1.5rem;
}

@media (max-width: 576px) {
  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .card-actions {
    margin-top: 0.5rem;
  }
}
