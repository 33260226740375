/* Reset */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

/* Ustaw domyślną wartość zmiennej – szerokość sidebara */
:root {
  --sidebar-width: 250px;
}

/* Sidebar – pozycjonowany fixed po lewej stronie */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: var(--sidebar-width);
  transition: width 0.3s ease;
  /* Dodatkowe style sidebaru, np. tło, cienie, etc. */
}

/* Kontener zawartości – przesunięty o szerokość sidebara */
.content-wrapper {
  margin-left: var(--sidebar-width);
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
}

/* Topbar – pozycjonowany fixed, z lewym offsetem równym szerokości sidebara */
.topbar {
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  right: 0;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: left 0.3s ease;
  z-index: 900;
}

/* Główna zawartość – zajmuje całą szerokość rodzica (content-wrapper) */
.main-content {
  flex: 1;
  padding: 20px;
  margin-top: 60px; /* aby nie była zakrywana przez topbar */
  width: 100%;
  transition: width 0.3s ease;
}

/* Media query dla mniejszych ekranów – sidebar zwinięty */
@media (max-width: 768px) {
  :root {
    --sidebar-width: 80vw; /* Sidebar zajmuje dokładnie 80% szerokości ekranu */
  }

  .sidebar {
    transform: translateX(-100%); /* Domyślnie ukryty */
    width: var(--sidebar-width);
  }

  .sidebar.open {
    transform: translateX(0); /* Widoczny po otwarciu */
  }

  .content-wrapper {
    margin-left: 0; /* Zawartość nie przesuwa się */
    transition: transform 0.3s ease, filter 0.3s ease;
  }

  .content-wrapper.content-pushed {
    transform: translateX(var(--sidebar-width));
  }

  /* Dodany overlay dla .main-content, aby cały obszar (wraz z paddingiem) był przyciemniony */
  .content-wrapper.content-pushed .main-content {
    position: relative;
  }
  .content-wrapper.content-pushed .main-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    z-index: 1;
  }

  .topbar {
    left: 0; /* Topbar dopasowany do ekranu */
  }

  /* Dodane: zmiana tła topbara przy rozwiniętym sidebarze */
  .content-wrapper.content-pushed .topbar {
    background-color: #7c7c7d;
  }

  .mobile-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 800;
  }

  .main-content {
    padding: 0px;
  }
}

/* Media query dla telefonów (max-width: 576px) */
@media (max-width: 576px) {
  :root {
    --sidebar-width: 80vw; /* Sidebar zajmuje dokładnie 80% szerokości ekranu */
  }

  .sidebar {
    transform: translateX(-100%); /* Domyślnie ukryty */
    width: var(--sidebar-width);
  }

  .sidebar.open {
    transform: translateX(0); /* Widoczny po otwarciu */
  }

  .content-wrapper {
    margin-left: 0; /* Zawartość nie przesuwa się */
    transition: transform 0.3s ease, filter 0.3s ease;
  }

  .content-wrapper.content-pushed {
    transform: translateX(var(--sidebar-width));
  }

  /* Dodany overlay dla .main-content, aby cały obszar (wraz z paddingiem) był przyciemniony */
  .content-wrapper.content-pushed .main-content {
    position: relative;
  }
  .content-wrapper.content-pushed .main-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    z-index: 1;
  }

  .topbar {
    left: 0; /* Topbar dopasowany do ekranu */
  }

  /* Dodane: zmiana tła topbara przy rozwiniętym sidebarze */
  .content-wrapper.content-pushed .topbar {
    background-color: #7c7c7d;
  }

  .mobile-overlay {
    /* Dziedziczy te same style z max-width:768px */
    display: block;
    position: fixed;
    top: 0;
    left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 800;
  }
}
